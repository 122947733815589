import GLightbox from 'glightbox';
import {Carousel} from 'bootstrap';
import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';
import imagesLoaded from 'imagesloaded';

jQueryBridget('masonry', Masonry, $);
jQueryBridget( 'imagesLoaded', imagesLoaded, $ );

$ ->
  if ('#frontend_collections_index').length > 0
    $('.collection-card').each (index, obj) ->
      $(this).click (e) ->
        location.href = $(this).data('collection-path')

  if $('#collection').length > 0
    $('#collection').imagesLoaded ->
      $('#collection').masonry
        itemSelector: '.grid-item'
      return

  GLightbox({
    'descPosition': 'left',
    'moreText': 'Mehr anzeigen...',
    'moreLength': 0
  })

  myCarousel = document.querySelector('#making_of_images_carousel')
  new (Carousel)(myCarousel)
