// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

require('glightbox');

// Import feather and export it to the global window object. This makes it
// available globally. We need to call replace() at the end of the rendering.
window.feather = require('./dashboardkit/feather.min');

require("./frontend/collections.js");
